/**
 * --------------------------------------------------------------
 * for loading overlay
 * --------------------------------------------------------------
 */
import $ from "jquery";
import "gasparesganga-jquery-loading-overlay";
export const loader = {
  methods: {
    loading: function (show = true) {
      $.LoadingOverlay(show ? "show" : "hide");
    },
  },
};
